.popup-content {
    
    padding: 20px;
    text-align: center;
    width: 300px;
    background: none;
    border: none;
}

.popup-content input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    background: none;
    color:white;
    
    
}
.popup-content input::placeholder {
    color:white;
    opacity: 0.7;
    
    
}

.submit-btn, .close-btn {
    padding: 8px 16px;
    margin: 5px;
    cursor: pointer;
    border-radius: 20px;
    background: white;
}
.text-id{
    font-size: 20px;
    font-weight: bold;
    color: white;
}
