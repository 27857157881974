.auth-buttons {
    display: flex;
    gap: 10px;
}

.btn-login,
.btn-signup {
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    font-family: "Plus Jakarta Sans", sans-serif;
    text-transform: uppercase;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Button background colors */
.btn-login {
    background-color: #ADD8E6; /* Light blue color */
}

.btn-login:hover {
    background-color: #92c3e0; /* Slightly darker light blue on hover */
    color: #ffffff; /* Keep text color white on hover */
}

.btn-signup {
    background-color: #87CEEB; /* Slightly darker light blue */
}

.btn-signup:hover {
    background-color: #70b6d6; /* Darker shade for hover */
    color: #ffffff; /* Keep text color white on hover */
}
