/* Full-page background image */
.signup-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background-image: url('../images/background-signup.jpg');
    background-size: cover;
    
}


/* Center the form on the page */
.signup-form-container {
  
    background-color: #ffffff;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    text-align: center;
    margin: 10px 0;
}

/* Form title */
.signup-form-container h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
}

/* Style the input fields */
.signup-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
    margin: 10px 0;
}

/* Add placeholder text color */
.signup-form input::placeholder {
    color: #bbbbbb;
}

/* Style the register button */
.register-btn {
    width: 100%;
    background-color: #007bff; /* Light blue color */
    color: #ffffff;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
}

/* Register button hover effect */
.register-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
}
.logo-image {
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}
@media (max-width: 768px) {
    .signup-page {
        height: 100vh;
    }
    
    .logo-image {
        width: 100px;
        height: 100px;
    }
    
}