.service-section {
    padding: 50px 0;
    background-color: #f9f9f9;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.section-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #333;
}

.section-subtitle {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 30px;
    color: #666;
}

.service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.service-item {
    flex: 1 1 calc(45% - 10px); /* Adjusts for two columns with gap */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-item h3 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.service-item p {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
}

@media (max-width: 767px) {
    .service-item {
        flex: 1 1 100%; /* Stacks items on smaller screens */
    }
}
