/* General Footer Styling */
.wpo-site-footer-s2 {
    font-size: 0.9em;
}

/* Upper Footer Styling */
.wpo-upper-footer-custom.small-padding {
    padding: 20px 0;
}

/* Address Widget Styling */
.address-widget-custom {
    display: flex;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
}

.address-widget-custom p {
   
    padding: 1px;
}

/* Logo Styling */
.logo img {
    max-height: 40px; /* Reducing logo size */
}

