/*3.2 wpo-about-section */
.wpo-about-section,
.wpo-about-section-s4 {
    position: relative;
    padding-top: 200px;
    padding-bottom: 130px;

    @media (max-width:991px) {
        padding: 90px 0;
    }

    @media (max-width:767px) {
        padding: 80px 0;
    }

    .about-left {
        position: relative;
        max-width: 605px;
        height: 620px;
        margin-right: 55px;

        @media (max-width:1399px) {
            margin-right: 0;
        }

        .image-1 {
            border-radius: 7px;
            border: 9px solid #FFF;
            max-width: 404px;
            max-height: 500px;

            img {
                width: 100%;
            }
        }

        .image-2 {
            border-radius: 10px;
            border: 8px solid #FFF;
            box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.17);
            max-width: 318px;
            max-height: 268px;
            position: absolute;
            right: 33px;
            bottom: 0;

            @media (max-width:1199px) {
                right: 0px;
            }

            img {
                width: 100%;
            }
        }

        .image-3 {
            border-radius: 9px;
            border: 7px solid #FFF;
            box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.13);
            position: absolute;
            right: 53px;
            top: 20px;

            @media (max-width:1199px) {
                right: 0px;
            }

            @media (max-width:499px) {
                display: none;
            }

            img {
                width: 100%;
            }
        }

        .image-4 {
            position: absolute;
            right: -33px;
            bottom: -24px;
            z-index: -1;

            img {
                width: 100%;
            }
        }

        .since {
            border-radius: 76px 0px 76px 76px;
            background: $text-light-color-s3;
            width: 254px;
            height: 152px;
            position: absolute;
            left: -23px;
            bottom: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: end;

            @media (max-width:767px) {
                height: 120px;
            }

            @media (max-width:399px) {
                border-radius: 0;
            }

            &::before {
                position: absolute;
                left: 40px;
                top: 50%;
                content: '';
                width: 2px;
                height: 65px;
                background: #FFF;
                transform: translateY(-50%);
            }

            &::after {
                width: 2px;
                height: 65px;
                position: absolute;
                left: 73px;
                top: 50%;
                content: '';
                width: 2px;
                height: 65px;
                background: #FFF;
                transform: translateY(-50%);
                transform: rotate(-90deg);
            }

            h2 {
                margin-bottom: 0;

                span {
                    font-family: $heading-font;
                    color: $white;
                    font-size: 61px;
                    font-weight: 600;
                    line-height: 50px;
                    text-transform: capitalize;

                    @media (max-width:1199px) {
                        font-size: 52px;
                    }

                    @media (max-width:767px) {
                        font-size: 40px;
                    }
                }
            }

            p {
                color: $white;
                font-size: 23px;
                font-weight: 700;
                line-height: 32px;
                text-transform: capitalize;
                display: block;
                margin-bottom: 0;
                margin-top: -5px;
            }
        }
    }

    .shape {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;

        @media (max-width:767px) {
            display: none;
        }
    }

    .side-img {
        position: absolute;
        right: 0;
        bottom: 90px;
        z-index: -1;

        @media (max-width:767px) {
            display: none;
        }
    }

    .about-content {
        @media (max-width:991px) {
            margin-top: 50px;
        }

        .title {
            h2 {
                color: $theme-primary-color-s2;
                font-family: $base-font;
                font-size: 16px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 1.76px;
                text-decoration-line: underline;
                text-transform: uppercase;
                position: relative;
                padding-left: 20px;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 45%;
                    width: 14px;
                    height: 16px;
                    transform: translateY(-50%);
                    content: url(../../images/title-shape.svg);
                }
            }

            h3 {
                color: #042127;
                font-size: 48px;
                font-weight: 700;
                line-height: 55px;
                text-transform: capitalize;
                margin-bottom: 0;

                @media (max-width:991px) {
                    font-size: 40px;
                    line-height: 50px;
                }

                @media (max-width:499px) {
                    font-size: 35px;
                    line-height: 40px;
                }
            }
        }

        .sub-content {
            margin-top: 37px;
            margin-bottom: 25px;

            span {
                color: #535353;
                font-size: 16px;
                font-weight: 400;
                line-height: 28.8px;
                padding-left: 10px;
                display: block;
                border-left: 1px solid $theme-primary-color-s2;
            }
        }

        h4 {
            color: $text-light-color-s2;
            font-size: 18px;
            font-weight: 700;
            line-height: 33px;
            text-transform: capitalize;
            margin-bottom: 30px;
        }

        ul {
            li {
                color: $text-light-color-s2;
                font-size: 16px;
                font-weight: 500;
                line-height: 33px;
                text-transform: capitalize;
                padding-left: 30px;
                position: relative;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: flaticon_logistics !important;
                    content: "\f126";
                    font-size: 14px;
                    color: rgba(11, 160, 137, 1);
                    width: 18px;
                    height: 18px;
                    text-align: center;
                    line-height: 18px;
                    border-radius: 100px;
                    border: 1px solid rgba(11, 160, 137, 1);
                }
            }
        }

        .author-btn {
            max-width: 490px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 60px;

            @media (max-width:399px) {
                display: block;
                margin-top: 40px;
            }
        }

        .author {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:399px) {
                justify-content: start;
            }

            .image {
                width: 60px;
                height: 60px;

                img {
                    border-radius: 60px;
                    border: 4px solid #FFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
                    width: 100%;
                }
            }

            .text {
                margin-left: 10px;
                display: grid;

                span {
                    margin-top: -10px;
                    color: #0BA089;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 30px;
                }
            }
        }

        .theme-btn {
            background: #0B3742;

            @media (max-width:399px) {
                margin-top: 20px;
            }
        }
    }
}

.wpo-about-section-s4 {
    padding: 120px 0;

    @media (max-width:991px) {
        padding: 90px 0;
    }
}

/*3.3 wpo-service-section */
.wpo-service-section {
    position: relative;
    z-index: 1;
    background: #FFF5F0;

    .wpo-section-title {
        text-align: left;
        max-width: 585px;

        @include media-query(991px) {
            margin-bottom: 20px;
        }

        h3 {
            @include media-query(1199px) {
                font-size: 40px;
            }

            @include media-query(767px) {
                font-size: 32px;
                line-height: 40px;
            }


            @include media-query(330px) {
                font-size: 30px;
            }
        }
    }

    .service-all-btn {
        text-align: end;
        margin-bottom: 60px;

        @include media-query(991px) {
            text-align: left;
        }
    }
}

.service-slider {
    padding: 0 70px;

    @media (max-width:1599px) {
        padding: 0 20px;
    }

    @media (max-width:575px) {
        padding: 0 10px;
    }


    .slick-list {
        margin-bottom: 30px;
    }

    .slick-dots {
        text-align: center;
        bottom: auto;
        z-index: 11;
    }

    .slick-dots li {
        display: inline-block;
        width: 45px;
    }

    .slick-dots li button {
        height: 5px;
        width: 40px;
        border: 0;
        border-radius: 100px;
        color: $small-black;
        margin: 0 5px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
        transition: all .3s ease-in-out;
        overflow: hidden;
        position: relative;
        display: block;

        @media (max-width:767px) {
            width: 30px;
        }

        &::before {
            display: none;
        }

    }

    .slick-dots li {
        &.slick-active button {
            background: $small-black;
        }
    }


}

.service-card {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        content: '';
        background: radial-gradient(272.74% 97.55% at 66.64% 8.36%, rgba(0, 7, 31, 0.00) 0%, rgba(4, 43, 51, 0.91) 99.98%, rgba(4, 43, 51, 0.91) 99.99%);
        transition: all .4s ease-in-out;
        z-index: 1;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: -1;
    }

    .content {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 340px;
        height: 100%;
        display: flex;
        z-index: 11;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 40px;
        padding-right: 60px;
        overflow: hidden;

        @media (max-width:991px) {
            padding-left: 30px;
            padding-right: 30px;
            max-width: 330px;
        }

        @media (max-width:767px) {
            justify-content: center;
        }

        @media (max-width:575px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: #fff;
            transition: all 1.5s ease-in-out;
            z-index: -1;
        }

        i {
            color: $small-black;
            font-size: 78px;
            transition: all 1.5s ease-in-out;

            @media (max-width:991px) {
                font-size: 50px;
            }

            @media (max-width:575px) {
                font-size: 40px;
            }
        }

        .text {

            span {
                color: $text-light-color-s3;
                font-size: 22px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -0.66px;
                display: inline-block;
                margin-bottom: 10px;

                @media (max-width:991px) {
                    margin-bottom: 0;
                }
            }

            h2 {
                margin-bottom: 20px;

                @media (max-width:991px) {
                    margin-bottom: 10px;
                }

                a {
                    color: #222;
                    font-family: $heading-font;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: -0.72px;
                    text-transform: capitalize;
                    transition: all 1.5s ease-in-out;
                }
            }

            p {
                color: #5E5F63;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                transition: all 1.5s ease-in-out;
            }

            .service-single-link {
                width: 76px;
                height: 47px;
                line-height: 47px;
                border-radius: 23.5px;
                background: #F6F2ED;
                display: block;
                text-align: center;
                margin-top: 30px;

                @media (max-width:575px) {
                    margin-top: 0px;
                    width: 50px;
                    height: 30px;
                    line-height: 47px;
                }

                i {
                    transform: rotate(-40deg);
                    color: #222;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 45px;
                    position: absolute;
                    left: 70px;

                    @media (max-width:991px) {
                        left: 60px;
                        line-height: 45px;
                    }

                    @media (max-width:575px) {
                        left: 28px;
                        font-size: 13px;
                        line-height: 32px;
                    }
                }
            }
        }
    }

    &:hover {
        &::before {
            width: 100%;
        }

        .content {
            i {
                opacity: 0;
                visibility: hidden;
            }

            &::before {
                width: 0;
            }

            .text {

                h2 {

                    a {
                        color: $white;
                    }
                }

                p {
                    color: #C2C2C2;
                }

                .service-single-link {

                    i {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

/*3.4 wpo-funfact-section */
.wpo-funfact-section {
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .titel-image {
        text-align: center;
        margin-bottom: 65px;

        @media (max-width:767px) {
            margin-bottom: 40px;
        }

        h1 {
            background: url(../../images/text-img.jpg) center / cover no-repeat;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: $heading-font;
            font-size: 200px;
            font-weight: 700;
            margin-bottom: 0;
            padding-bottom: 60px;
            margin-top: -20px;

            @media (max-width:1199px) {
                font-size: 150px;
            }

            @media (max-width:767px) {
                font-size: 100px;
                padding-bottom: 20px;
            }

            @media (max-width:499px) {
                font-size: 50px;
                padding-bottom: 20px;
            }
        }

        h3 {
            color: #0D1224;
            font-size: 23px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 0;

            @media (max-width:767px) {
                font-size: 18px;
                line-height: 20px;
            }

            @media (max-width:499px) {
                font-size: 15px;
            }
        }
    }

    .item {
        text-align: center;
        margin-bottom: 30px;

        h2 {
            color: $small-black;
            font-family: $heading-font;
            font-size: 50px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: -1px;
            max-width: 286px;
            height: 100px;
            line-height: 100px;
            border-radius: 100px;
            background: linear-gradient(107deg, rgba(11, 55, 66, 0.12) 38.02%, rgba(28, 140, 168, 0.00) 89.08%);
            margin: 0 auto;

            @media (max-width:1199px) {
                font-size: 35px;
            }
        }

        h3 {
            color: #0D1224;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            margin-top: 30px;
            margin-bottom: 0;

            @media (max-width:767px) {
                margin-top: 15px;
            }
        }
    }
}

/*3.5 wpo-get-quate-section */
.wpo-get-quate-section {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: #EBEBEB;
        z-index: -1;
    }

    .left-content {
        text-align: left;

        @media (max-width:991px) {
            margin-bottom: 50px;
        }

        .wpo-section-title {
            text-align: left;
            margin-bottom: 40px;

            h3 {
                color: #FFF;

                @media (max-width:1199px) {
                    font-size: 40px;
                }

                @include media-query(767px) {
                    font-size: 32px;
                    line-height: 40px;
                }


                @include media-query(399px) {
                    font-size: 25px;
                }
            }
        }

        .quat {
            max-width: 514px;
            border-radius: 0 5px 5px 0;
            background: #0B3D47;
            border-left: 2px solid #FF7D44;
            position: relative;
            padding: 20px 55px 20px 35px;
            margin-bottom: 45px;

            @media (max-width:1199px) {
                padding: 20px 30px 20px 25px;
            }

            &::before {
                position: absolute;
                content: '';
                width: 9px;
                height: 10px;
                left: 0;
                top: 50%;
                background: #FF7D44;
                transform: translateY(-50%);
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
            }

            p {
                margin-bottom: 0;
                color: #FFF;
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
            }
        }


        .get-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 500px;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                @include media-query(767px) {
                    flex-direction: column;
                    max-width: 200px;
                }

                i {
                    font-size: 50px;
                    line-height: 35px;
                    color: $theme-primary-color-s2;
                }

                h3 {
                    color: $white;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                    letter-spacing: -0.54px;
                    margin-bottom: 0;
                    margin-left: 12px;

                    @media (max-width:1199px) {
                        font-size: 15px;
                        line-height: 24px;
                    }

                    @include media-query(767px) {
                        margin-left: 0px;
                        margin-top: 20px;
                        text-align: center;
                    }
                }
            }
        }

        .quate-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 500px;
            margin-top: 10px;

            @include media-query(767px) {
                display: block;
            }


            li {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                @include media-query(767px) {
                    margin-bottom: 10px;
                }

                i {
                    font-size: 25px;
                    line-height: 15px;
                    color: $theme-primary-color-s2;

                    @include media-query(767px) {
                        font-size: 20px;
                        line-height: 15px;
                    }
                }

                h3 {
                    color: $white;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 30px;
                    margin-bottom: 0;
                    margin-left: 12px;

                    @media (max-width:1199px) {
                        font-size: 15px;
                        line-height: 22px;
                    }
                }
            }
        }

        .theme-btn {
            margin-top: 30px;
        }
    }


    .left-bg {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 60%;
        background: rgba(7, 46, 54, 1);
        z-index: -1;

        @media (max-width:991px) {
            width: 100%;
        }

        .img1 {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;

            @media (max-width:1199px) {
                display: none;
            }
        }

        .img2 {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -11;
        }

        .img3 {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -11;
        }
    }

    .rigth-bg {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;

        @media (max-width:1199px) {
            width: 40%;
        }

        @media (max-width:991px) {
            display: none;
        }
    }
}

.quote-form {
    margin-left: 45px;
    border-radius: 9px;
    border: 13px solid #FFF;
    background: $theme-primary-color-s2;
    max-width: 485px;
    padding: 60px 45px;

    @media (max-width:1199px) {
        margin: 0;
    }

    @media (max-width:991px) {
        border: 7px solid #FFF;
    }

    @media (max-width:575px) {
        padding: 40px 20px;
    }

    @media (max-width:399px) {
        padding: 40px 10px;
    }


    .title {
        margin-bottom: 20px;

        h3 {
            color: $white;
            font-size: 30px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 0;

            @media (max-width:399px) {
                font-size: 20px;
            }
        }
    }

    .item {
        margin-bottom: 20px;
    }

    label {
        color: $white;
        font-size: 14px;
        font-weight: 700;
        line-height: 25px;
        margin-bottom: 7px;
    }

    .srv-validation-message {
        display: none;
    }

    .error {
        color: $white;
    }

    .form-control {
        border-radius: 6px;
        color: $black;
        font-size: 14px;
        font-weight: 500;
        font-family: $heading-font;
        line-height: 30px;
        padding: 8px 20px;
        border: 0;
        position: relative;

        &:focus {
            color: $black;
            border: 0;
            border-color: transparent;
            outline: 0;
            box-shadow: 0 0 0 .25rem transparent;
        }

        &::-webkit-input-placeholder {
            color: $black;
        }

        &::-moz-placeholder {
            color: $black;
        }

        &::-moz-placeholder {
            color: $black;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: $white;
            border-radius: 6px;
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        display: inline-block;
        color: #a9a9a9;
        cursor: pointer;
        opacity: 1;
        -webkit-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: transparent url(../../images/down-arrow.svg) no-repeat calc(100% - 15px) center;
        position: relative;
        z-index: 1;
        background-color: $white;
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        @include media-query(399px) {
            display: block;
        }

        li {
            margin-bottom: 20px;

            input[type=checkbox] {
                position: relative;
                cursor: pointer;
            }

            input[type=checkbox]:checked:before {
                content: "";
                display: block;
                position: absolute;
                width: 20px;
                height: 20px;
                border: 1px solid $white;
                border-radius: 1px;
                background-color: $small-black;
                transition: all 0.2s linear;
            }


            input[type=checkbox]:before {
                content: "";
                display: block;
                position: absolute;
                width: 20px;
                height: 20px;
                border: 2px solid $white;
                border-radius: 1px;
                background-color: $theme-primary-color-s2;
            }


            input[type=checkbox]:after {
                content: "";
                display: block;
                width: 0px;
                height: 0px;
                border: solid $white;
                border-width: 0 0px 0px 0;
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
                position: absolute;
                top: 0px;
                left: 7px;
                transition: all 0.2s linear;
            }

            input[type=checkbox]:checked:after {
                content: "";
                display: block;
                width: 6px;
                height: 10px;
                border: solid $white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                position: absolute;
                top: 4px;
                left: 7px;
            }

            label {
                margin-bottom: 0;
                margin-left: 10px;
                color: #FFF;
                cursor: pointer;
            }




        }
    }

    .theme-btn {
        margin-top: 24px;
        width: 100%;
        background: $small-black;
        border-radius: 8px;
        border: 0;
        padding: 19px 0;
    }


}

/*3.6 wpo-features-section  */
.wpo-features-section {

    h4 {
        text-align: center;
        color: #1D1729;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
        text-transform: lowercase;
        margin-bottom: 0;
        margin-top: 30px;

        a {
            color: #072E36;
            text-decoration-line: underline;
            text-transform: lowercase;
            display: inline-block;

            span {
                width: 29px;
                height: 29px;
                line-height: 29px;
                border-radius: 29px;
                text-align: center;
                background: #FFF;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
                margin-left: 12px;
                text-decoration-line: none;
                display: inline-block;
            }
        }
    }
}

.features-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:1199px) {
        flex-wrap: wrap;
    }
}

.features-card {
    text-align: center;
    width: 20%;
    max-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #EDEEEE;
    background: #FFF;
    box-shadow: 0px 9px 29px 0px rgba(0, 0, 0, 0.04);
    padding: 50px 15px;
    transition: all .3s ease-in-out;
    margin: 0 10px;
    margin-bottom: 20px;

    @media (max-width:1199px) {
        width: 25%;
    }

    @media (max-width:991px) {
        width: 30%;
    }

    @media (max-width:767px) {
        width: 45%;
    }

    @media (max-width:575px) {
        width: 100%;
    }

    i {
        font-size: 80px;
        color: #072E36;
        transition: all .3s ease-in-out;

        @media (max-width:1399px) {
            font-size: 60px;
        }
    }

    h3 {
        color: #1D1729;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.2px;
        text-transform: capitalize;
        transition: all .3s ease-in-out;
        margin-bottom: 0;

        @media (max-width:1399px) {
            font-size: 18px;
        }
    }


    &:hover {
        background: #072E36;
        border: 1px solid transparent;
        box-shadow: 0px 9px 29px 0px transparent;

        i {
            color: $white;
        }

        h3 {
            color: $white;
        }
    }

}

/*3.7 wpo-process-section */
.wpo-process-section {
    background: url(../../images/process-bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding-bottom: 90px;

    @media (max-width: 991px) {
        padding-bottom: 60px;
    }

    @media (max-width: 767px) {
        padding-bottom: 50px;
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: -1;
        background: linear-gradient(98deg, rgba(7, 46, 54, 0.92) 27.49%, rgba(7, 46, 54, 0.92) 27.5%, rgba(7, 46, 54, 0.92) 90.05%);
    }

    .process-wrap {
        position: relative;
    }

    .process-wrap .border-shape {
        position: absolute;
        left: 9%;
        top: 20%;
        width: 100%;
    }

    @media (max-width: 991px) {
        .process-wrap .border-shape {
            display: none;
        }
    }

    .process-item {
        margin-bottom: 30px;
    }

    .process-item .top-contnent {
        width: 190px;
        height: 190px;
        box-shadow: 0px 8px 23px rgba(0, 0, 0, 0.07);
        border-radius: 100%;
        position: relative;
        margin: 0 auto;
        text-align: center;
        z-index: 1;
        transition: all 0.3s ease-in-out;

        img {
            border-radius: 100%;
            padding: 5px;
        }

    }

    .process-item .top-contnent .item-shape {
        position: absolute;
        left: 39%;
        top: 37%;
        width: 145%;
        height: 100%;
        transform: translate(-50%, -50%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }

    .process-item .top-contnent .item-shape img {
        width: 100%;
    }

    .process-item .top-contnent .text {
        position: absolute;
        left: 16px;
        bottom: 12px;
        z-index: 1;
        width: 170px;
        height: 60px;
        background: $theme-primary-color;
        margin: 0 auto;
        -webkit-clip-path: polygon(0 0, 100% 19%, 95% 100%, 10% 100%);
        clip-path: polygon(0 0, 100% 19%, 95% 100%, 10% 100%);
        transform: rotate(2deg);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;

    }

    .process-item .top-contnent .text span {
        color: #FFF;
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        text-transform: capitalize;
        transition: all 0.3s ease-in-out;
    }

    .process-item .top-contnent .shape-1 {
        position: absolute;
        left: 8px;
        bottom: -12px;
        width: 100%;
        transform: rotate(13deg);
    }

    .process-item .top-contnent .shape-2 {
        position: absolute;
        right: 14px;
        bottom: 0px;
        transform: rotate(13deg);
    }

    .process-item .top-contnent .shape-2 svg path {
        fill: #373940;
        transition: all 0.3s ease-in-out;
    }

    .process-item .content {
        text-align: center;
        max-width: 220px;
        margin: 0 auto;
        margin-top: 30px;
    }

    .process-item .content h2 {
        color: $white;
        font-size: 22px;
        font-weight: 700;
        text-transform: capitalize;
    }

    .process-item .content p {
        color: #C2C2C2;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 0;
    }

    .process-item:hover .top-contnent .item-shape {
        opacity: 1;
        visibility: visible;
    }

    .process-item:hover .top-contnent .text {
        background: $theme-primary-color-s2;
    }

    .process-item:hover .top-contnent .shape-2 svg path {
        fill: $theme-primary-color-s2;
    }
}

/*3.8 wpo-process-section */
.wpo-project-section {
    position: relative;
    z-index: 1;
    background: #FFF5F0;
    padding-bottom: 90px;

    @media (max-width: 991px) {
        padding-bottom: 60px;
    }

    @media (max-width: 767px) {
        padding-bottom: 50px;
    }

    &::before {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        height: 100%;
        width: 30%;
        background: $theme-primary-color;
        z-index: -1;

        @media (max-width:991px) {
            height: 40%;
            width: 100%;
            top: auto;
            bottom: 0;
        }
    }

    .row.align-items-center {
        margin-bottom: 60px;

        @media (max-width:991px) {
            margin-bottom: 40px;
        }
    }

    .wpo-section-title {
        margin-bottom: 0;
        text-align: left;
        max-width: 615px;

        @media (max-width:991px) {
            text-align: center;
            margin-bottom: 20px;
            max-width: 100%;
        }
    }

    .project-all {
        text-align: right;

        @media (max-width:991px) {
            text-align: center;
        }

        .theme-btn {
            background: $white;
            color: $black;


            &::before {
                background-color: $theme-primary-color-s2;
            }

            &:after {
                background-color: $theme-primary-color-s2;
            }

            &:hover {
                color: $white;
            }
        }
    }

    .side-img {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;

        @media (max-width:991px) {
            display: none;
        }

    }

}

.project-slider {
    margin-right: -220px;

    @media (max-width:1799px) {
        margin-right: -150px;
    }

    @media (max-width:1659px) {
        margin-right: -100px;
    }

    @media (max-width:1499px) {
        margin-right: -50px;
    }

    @media (max-width:1399px) {
        margin-right: 0px;
    }
}

.project-card {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 30px;

    &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: linear-gradient(180deg, rgba(6, 45, 53, 0.00) 0%, rgba(6, 45, 53, 0.00) 64.33%, #062D35 100%);
        z-index: 1;
    }

    span {
        color: #FFF;
        text-align: center;
        font-family: $heading-font;
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        border-radius: 13px;
        background: $theme-primary-color-s2;
        width: 43px;
        height: 27px;
        line-height: 27px;
    }

    img {
        width: 100%;
        transition: all .8s ease-in-out;
        transform: scale(1);
    }

    &:hover {
        img {
            transform: scale(1.2);
        }
    }

    .content {
        position: absolute;
        left: 20px;
        bottom: 25px;
        z-index: 11;

        h2 {
            margin-bottom: 0;

            a {
                background: $dark-gray;
                color: $white;
                max-width: 233px;
                padding: 10px 16px;
                font-family: $heading-font;
                font-size: 16px;
                font-weight: 600;
                text-transform: capitalize;
                display: inline-block;
            }
        }

        h4 {
            background: $theme-primary-color-s2;
            color: #1D1729;
            padding: 8px 16px;
            font-family: $heading-font;
            text-transform: capitalize;
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
}

/*3.9 wpo-testimonial-section */
.wpo-testimonial-section,
.wpo-testimonial-section-s2 {
    padding-bottom: 0;

    .testimonial-wrap {
        background: #FFF;
        box-shadow: 0px 4px 31px 0px rgba(0, 0, 0, 0.10);
        padding: 65px 70px 65px 50px;
        display: flex;
        align-items: center;

        @media (max-width:1399px) {
            padding: 40px 30px 40px 30px;
        }

        @media (max-width:991px) {
            display: block;
        }

        @media (max-width:575px) {
            padding: 20px;
        }
    }

    .image {
        max-width: 428px;
        max-height: 519px;
        width: 428px;
        display: inline-block;

        @media (max-width:767px) {
            width: 100%;
        }

        .item {
            position: relative;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .feedback {
            padding: 15px 24px;
            background: $theme-primary-color-s2;
            text-align: center;
            color: #FFF;
            font-size: 20px;
            font-weight: 600;
            line-height: 29px;
            display: inline-block;
            position: absolute;
            left: -66px;
            bottom: 135px;
            transform: rotate(270deg);
            display: flex;

            @media (max-width:575px) {
                display: none;
            }

            img {
                margin-right: 15px;
            }
        }
    }

    .content-wrap {
        width: calc(100% - 435px);
        display: inline-block;
        padding-left: 95px;
        text-align: left;

        @media (max-width:1399px) {
            padding-left: 30px;
        }

        @media (max-width:991px) {
            padding-left: 0px;
            width: 100%;
            margin-top: 30px;
        }


        h2 {
            color: $theme-primary-color-s2;
            font-family: $base-font;
            font-size: 16px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 1.76px;
            text-decoration-line: underline;
            text-transform: uppercase;
            position: relative;
            padding-left: 20px;
            display: inline-block;

            @media (max-width:1399px) {
                font-size: 16px;
                font-weight: 700;
                line-height: 30px;
            }

            @include media-query(767px) {
                font-size: 13px;
            }

            &::before {
                position: absolute;
                left: 0;
                top: 45%;
                width: 14px;
                height: 16px;
                transform: translateY(-50%);
                content: url(../../images/title-shape.svg);
            }

        }

        h3 {
            text-transform: capitalize;
            color: $heading-color;
            font-family: $heading-font;
            font-size: 48px;
            font-weight: 700;
            line-height: 55px;
            margin-bottom: 50px;

            @include media-query(1199px) {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 30px;
            }


            @include media-query(575px) {
                font-size: 30px;
                margin-bottom: 10px;
            }




        }
    }

    .content {
        @media (max-width:575px) {
            padding-bottom: 50px;
        }

        p {
            color: #222;
            font-size: 30px;
            font-weight: 500;
            line-height: 52px;
            letter-spacing: -0.9px;
            margin-bottom: 35px;

            @media (max-width:1399px) {
                font-size: 25px;
                line-height: 40px;
            }

            @media (max-width:575px) {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .client-name {
            border-top: 1px solid rgba(24, 24, 94, 0.10);
            padding-top: 40px;

            h4 {
                color: #222;
                font-family: $base-font;
                font-size: 22px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -0.66px;
                margin: 0;
                display: inline;
            }

            span {
                display: inline;
                color: #696969;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }


    .slick-arrow {
        position: absolute;
        top: auto;
        left: auto;
        right: 0;
        bottom: -30px;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background: #F1F1F1;
        transition: all .3s ease-in-out;
        z-index: 1;

        &.slick-prev,
        &.slick-next {
            right: 80px;

            &::after {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: "\f125";
                font-family: flaticon_logistics !important;
                font-size: 15px;
                color: rgba(8, 17, 45, 1);
                transition: all .3s ease-in-out;
            }

            &::before {
                display: none;
            }
        }

        &.slick-next {
            right: 0;

            &::after {
                content: "\f124";
            }

            &::before {
                display: none;
            }
        }

        &:hover {
            background: rgba(6, 45, 53, 1);

            &::after {
                color: $white;
            }
        }
    }
}

/*3.10 wpo-partner-section */
.wpo-partner-section,
.wpo-partner-section-s2,
.wpo-partner-section-s3 {

    .partner-grids {
        display: flex;
        flex-wrap: wrap;
    }

    .grid {
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;


        @include media-query(767px) {
            flex-basis: 50%;
        }

        @include media-query(440px) {
            flex-basis: 50%;
        }

        img {
            display: inline-block;
            max-width: 70%;
            margin: 0 auto;
            width: 142px;
            height: 45px;

            @media(max-width:991px) {
                width: 100%;
            }
        }
    }

    .owl-nav {
        display: none;
    }

}

/*3.11 wpo-cta-section */
.wpo-cta-section,
.wpo-cta-section-s2,
.wpo-cta-section-s3 {
    position: relative;
    z-index: 11;

    .cta-wrapr {
        padding: 60px 50px 60px 75px;
        background: url(../../images/cta-bg.jpg) center / cover no-repeat;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width:991px) {
            padding: 60px 50px;
            display: block;
        }

        @media(max-width:575px) {
            padding: 40px 20px;
        }

        .wpo-section-title {
            width: calc(100% - 350px);
            text-align: left;
            margin-bottom: 0;

            @media(max-width:991px) {
                width: 100%;
                margin-bottom: 50px;
            }

            h3 {
                color: #FFF;
                font-size: 38px;
                font-weight: 700;
                line-height: 47px;
                text-transform: capitalize;
                max-width: 560px;
                margin-bottom: 30px;

                @media (max-width:1199px) {
                    font-size: 35px;
                }
            }

            .theme-btn {
                font-family: $heading-font;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.7px;
                text-transform: uppercase;
                padding: 17px 22px;
            }
        }

        .contact-info {
            max-width: 350px;

            .item {
                display: flex;
                align-items: center;
                border-radius: 47.5px;
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(10px);
                color: $theme-primary-color-s2;
                padding: 15px 40px 15px 30px;

                @media(max-width:575px) {
                    padding: 15px 20px;
                }

                &:first-child {
                    margin-bottom: 30px;
                }

                i {
                    font-size: 35px;
                    line-height: 50px;
                    position: relative;
                    bottom: -7px;


                    @media(max-width:575px) {
                        font-size: 25px;
                        line-height: 30px;
                    }

                    &::before {
                        line-height: 0;
                    }
                }

                .text {
                    padding-left: 20px;

                    @media(max-width:575px) {
                        padding-left: 10px;
                    }

                    span {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                    }

                    p {
                        color: #FFF;
                        font-size: 21px;
                        font-weight: 600;
                        line-height: 30px;
                        letter-spacing: -0.63px;
                        margin-bottom: 0;

                        @media(max-width:575px) {
                            font-size: 15px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }

    }
}

/*3.12 wpo-team-section */
.wpo-team-section,
.wpo-team-section-s2,
.wpo-team-section-s3 {
    position: relative;
    z-index: 1;
    background: #FFF5F0;
    padding-top: 290px;
    margin-top: -150px;

    @media (max-width:991px) {
        margin-top: 90px;
        padding-top: 90px;
    }

    @media (max-width:767px) {
        margin-top: 80px;
        padding-top: 80px;
    }

    .wpo-section-title {
        text-align: left;
    }

    .team-slider {
        position: relative;
    }

    .slick-arrow {



        @media (max-width:991px) {
            display: none;
        }
    }

    .slick-arrow.slick-prev {
        border-radius: 66px;
        border: 1px solid rgba(0, 0, 0, 0.28);
        width: 66px;
        height: 66px;
        background: transparent;
        position: relative;
        transition: all .3s ease-in-out;
        position: absolute;
        right: 21.2%;
        top: -124px;
        left: auto;

        span {
            display: none;
        }

        &::before {
            display: none;
        }

        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: "\f125";
            font-family: flaticon_logistics !important;
            font-size: 15px;
            color: rgba(8, 17, 45, 1);
            transition: all .3s ease-in-out;
        }

        &:hover {
            background: $theme-primary-color-s2;
            border: 1px solid transparent;

            &::after {
                color: $white;
            }
        }
    }

    .slick-arrow.slick-next {
        border-radius: 66px;
        border: 1px solid rgba(0, 0, 0, 0.28);
        width: 66px;
        height: 66px;
        background: transparent;
        position: relative;
        margin-left: 20px;
        transition: all .3s ease-in-out;
        position: absolute;
        right: 16%;
        top: -124px;
        left: auto;

        @media (max-width:1399px) {
            right: 13%;
        }

        @media (max-width:1199px) {
            right: 10%;
        }

        span {
            display: none;
        }

        &::before {
            display: none;
        }

        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: "\f124";
            font-family: flaticon_logistics !important;
            font-size: 15px;
            color: rgba(8, 17, 45, 1);
            transition: all .3s ease-in-out;
        }

        &:hover {
            background: $theme-primary-color-s2;
            border: 1px solid transparent;

            &::after {
                color: $white;
            }
        }
    }

    .slick-track {
        padding: 0 -15px;
    }

    .slick-slide {
        padding: 0 15px;
    }

    .all-team-btn {
        text-align: center;
        margin-top: 20px;

        .theme-btn {
            padding: 21px 60px;
            font-weight: 600;

            @media (max-width:991px) {
                font-size: 14px;
                padding: 18px 45px;
            }
        }
    }

    .shape {
        position: absolute;
        left: 60px;
        top: 15%;
        z-index: -1;

        @media (max-width:991px) {
            display: none;
        }
    }

}

.wpo-team-section-s2,
.wpo-team-section-s3 {
    margin-top: 0;
    padding-top: 120px;

    @media (max-width:991px) {
        margin-top: 90px;
        padding-top: 90px;
    }

    @media (max-width:767px) {
        margin-top: 80px;
        padding-top: 80px;
    }
}

.team-card {
    max-width: 353px;

    @media (max-width:1199px) {
        margin: 0 auto;
        max-width: 100%;
    }

    @media (max-width:991px) {
        max-width: 450px;
    }

    .image {
        position: relative;

        img {
            width: 100%;
        }

        .icon {
            position: absolute;
            left: 40%;
            bottom: 20px;
            transform: translateX(-35%);
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;
        }

        ul {
            position: relative;
            background: #FFF;
            padding: 20px 35px;
            padding-bottom: 15px;
            display: block;
            text-align: center;

            @media (max-width:1499px) {
                padding: 20px 25px;
                padding-bottom: 15px;
            }

            @media (max-width:1399px) {
                padding: 10px 15px;
                padding-bottom: 5px;
            }

            &::before {
                position: absolute;
                left: 50%;
                bottom: -10px;
                width: 22px;
                height: 17px;
                background: #FFF;
                content: '';
                transform: translateX(-50%);
                clip-path: polygon(0 0, 50% 100%, 100% 0);
                z-index: -1;
            }

            li {
                display: inline-block;

                a {
                    font-size: 15px;
                    color: #888;
                    padding-right: 20px;
                    transition: all .3s ease-in-out;

                    &:hover {
                        color: #FF7236;
                    }
                }

                &:last-child {
                    a {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .content {
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
            max-width: 273px;
        }

        h2 {
            color: #000;
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
        }

        span {
            color: $theme-primary-color-s2;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
        }

        .hover-icon {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            border: 1px solid rgba(0, 0, 0, 0.08);
            background: #FFF;
            box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.04);
            margin-right: 20px;
            position: relative;
            transition: all .3s ease-in-out;

            &::before {
                position: absolute;
                content: '';
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 11px;
                height: 2px;
                background: #5D5D70;
                transition: all .3s ease-in-out;
            }

            &::after {
                position: absolute;
                content: '';
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 2px;
                height: 11px;
                background: #5D5D70;
                transition: all .3s ease-in-out;
            }
        }
    }

    &:hover {
        .image {
            .icon {
                opacity: 1;
                visibility: visible;
                bottom: 50px;
            }
        }

        .content {
            .hover-icon {
                transform: rotate(-45deg);
                background: $theme-primary-color;

                &::before {
                    background: $white;
                }

                &::after {
                    background: $white;
                }
            }
        }


    }
}

.wpo-team-section-s3 {
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
        margin-top: 0;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .team-card {
        max-width: 100%;

        @media (max-width:991px) {
            max-width: 100%;
        }

    }
}

/*3.13 wpo-blog-section */
.wpo-blog-section {
    position: relative;
    z-index: 1;
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .bg-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: -1;

        @media (max-width:991px) {
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}

.blog-card {
    border-radius: 16px;
    box-shadow: 0px 10px 60px 0px rgba(174, 174, 174, 0.20);
    background: $white;
    position: relative;
    margin-bottom: 30px;

    img {
        border-radius: 16px 16px 0 0;
        width: 100%;
    }

    .date {
        min-width: 70px;
        min-height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background: $theme-primary-color-s2;
        border-radius: 6px;
        position: absolute;
        right: 25px;
        top: -30px;

        h3 {
            color: $white;
            font-size: 28px;
            font-weight: 700;
            letter-spacing: -0.84px;
            margin-bottom: 0;

            @media (max-width:1199px) {
                font-size: 20px;
            }
        }

        span {
            color: $white;
            font-size: 18px;
            font-weight: 400;
        }
    }

    .content {
        background: $white;
        border-radius: 0 0 16px 16px;
        position: relative;

        .text {
            padding: 30px;
            padding-top: 35px;
            position: relative;

            @media (max-width:1199px) {
                padding: 15px;
                padding-top: 30px;
            }

            &::after {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1.5px;
                content: '';
                background: rgba(0, 23, 38, 0.10);
            }

            span {
                color: $theme-primary-color-s2;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: -0.32px;
            }

            h2 {
                margin-bottom: 0;

                a {
                    color: $heading-color;
                    font-family: $heading-font;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: -0.66px;
                    transition: all .3s ease-in-out;

                    @media (max-width:1199px) {
                        font-size: 18px;
                        line-height: 15px;
                    }

                    &:hover {
                        color: $theme-primary-color-s2;
                    }
                }
            }
        }

        .comment {
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:1199px) {
                padding: 15px;
                flex-direction: column;
                align-items: start;
            }

            li {
                display: flex;
                align-items: center;

                i {
                    color: #4D4B4B;
                    font-size: 18px;

                }

                span {
                    color: #4D4B4B;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                    letter-spacing: -0.32px;
                    padding-left: 7px;
                }
            }
        }

    }
}

/*3.14 wpo-map-section */
.wpo-map-section {
    .wpo-map {
        height: 410px;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: 0;
        outline: 0;
        filter: grayscale(100%);
    }

    h2.hidden {
        display: none;
    }
}