/* Desktop and Tablet Styles */
.status-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    overflow-y: auto; /* Allows scrolling if there's overflow */
    max-height: 80vh; /* Limits grid height to keep it manageable */
    padding-right: 10px; /* Extra space for scrollbar on desktop */
}

.status-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.status-item {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 250px; /* Adjusted width for a more compact look */
    flex-shrink: 0; /* Prevents items from shrinking */
}

.status-arrow {
    width: 25px;
    margin: 0 10px;
    align-self: center;
}

/* Mobile Styles */
@media (max-width: 767px) {
    .status-grid {
        flex-direction: column;
        align-items: center;
        gap: 15px;
        max-height: none; /* Allow full height usage on mobile */
        overflow-y: visible;
    }

    .status-item-container {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .status-item {
        max-width: 90%;
        padding: 12px;
    }

    .status-arrow {
        width: 20px;
        margin: 5px 0;
        content: url(../images/downarrow.png); /* Downward arrow for vertical layout */
    }
}
